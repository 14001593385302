<template>
    <div class="page-index">
        123
    </div>
</template>

<script>
export default {
    name: "Index",
    data() {
        return {};
    },
    created() {
        this.$router.replace({
            path: "/factory/index"
        });
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
</style>


